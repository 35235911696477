import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useAxios from 'axios-hooks';
import imgWindows from '@images/oslogo/windows.svg';
import imgIos from '@images/oslogo/ios.svg';
import imgAndroid from '@images/oslogo/google.svg';
import imgMacos from '@images/oslogo/macos_logo.svg';
import moment from 'moment';

const operatingSystemsEnum = Object.freeze({
  // macDownloadUrl
  MAC: 1,
  // chromeDownloadUrl
  CHROME: 2,
  // iOSDownloadUrl
  IOS: 3,
  // androidDownloadUrl
  ANDROID: 4,
  // windowsDownloadUrl
  WINDOWS: 5,
  // unknown
  UNKNOWN: 6,
});

export const operatingSystems = {
  [operatingSystemsEnum.MAC]: i18n.t('operatingSystem.macos'),
  [operatingSystemsEnum.CHROME]: i18n.t('operatingSystem.chromebook'),
  [operatingSystemsEnum.IOS]: i18n.t('operatingSystem.ios'),
  [operatingSystemsEnum.ANDROID]: i18n.t('operatingSystem.android'),
  [operatingSystemsEnum.WINDOWS]: i18n.t('operatingSystem.windows'),
  [operatingSystemsEnum.UNKNOWN]: i18n.t('operatingSystem.unknown'),
};

export const operatingSystemsImageUrl = {
  [operatingSystemsEnum.MAC]: 'os-macos',
  [operatingSystemsEnum.CHROME]: 'os-chrome',
  [operatingSystemsEnum.IOS]: 'os-ios',
  [operatingSystemsEnum.ANDROID]: 'os-android',
  [operatingSystemsEnum.WINDOWS]: 'os-windows',
  [operatingSystemsEnum.UNKNOWN]: '',
};

function useFormatedDownloads() {
  const downloads = [];

  function formatDownloads(list) {
    list.forEach(d => {
      const download = {};
      download.label = operatingSystems[d.operatingSystem];
      download.props = {
        href: d.url,
        imageUrl: operatingSystemsImageUrl[d.operatingSystem],
        releaseDate: d.releaseDate,
        version: d.version,
      };

      //* look for direct download links and apply correct props
      const regex = new RegExp(
        `^https?:\\/\\/(\\S)+(${process.env.FILE_DOWNLOAD_HOST})(\\S)+(${process.env.FILE_DOWNLOAD_EXTENSIONS})$`
      );
      if (regex.test(download.props.href)) {
        download.props = {
          ...download.props,
          download: true,
        };
      } else {
        download.props = {
          ...download.props,
          target: '_blank',
        };
      }

      downloads.push(download);
    });

    return downloads;
  }
  return { formatDownloads };
}

function DownloadSelector({
  url,
  label,
  version = 'latest',
  setLabel,
  versionData,
}) {
  const { t } = useTranslation();
  const [formattedOptions, setFormattedOptions] = useState([]);
  // eslint-disable-next-line
  const downloadLinks = versionData?.extendedDownloadLinks;
  const [{ data, loading, error }, getDownloads] = useAxios(
    {
      url,
      params: {
        version,
      },
      headers: {
        'X-API-Version': '3.0',
      },
    },
    {
      manual: true,
    }
  );
  const { formatDownloads } = useFormatedDownloads();

  //* url will override static download links
  useEffect(() => {
    if (url) getDownloads();
    // eslint-disable-next-line
  }, [url]);

  //* format downloads after getting response data
  useEffect(() => {
    if (data) {
      setFormattedOptions(formatDownloads(data.extendedDownloadLinks));
      if (setLabel) {
        const versionNumber = [
          data.majorVersion,
          data.minorVersion,
          data.revisionNumber,
        ];
        setLabel(versionNumber.join('.'));
      }
    }
    // eslint-disable-next-line
  }, [data]);

  //* use static download links only if url is not provided
  useEffect(() => {
    if (downloadLinks && !url)
      setFormattedOptions(formatDownloads(downloadLinks));
    // eslint-disable-next-line
  }, [downloadLinks]);

  if (error)
    return <div className="alert alert-danger">{t('errors.downloadList')}</div>;

  if (formattedOptions?.length > 0) {
    return (
      <div className="col-12">
        {formattedOptions.map((o, index) => (
          <>
            {index != 0 && <hr className="hr"></hr>}
            <div className="row" key={index}>
              <OSIcon className="col-1">
                <div className={o.props.imageUrl}></div>
              </OSIcon>
              <TabletPadding className="col-11">
                <div className="row">
                  <div className="col-12 h4">{o.label}</div>
                </div>
                <div className="row">
                  <div className="col-12 small">
                    {t('download.version') + ' ' + o.props.version} | {t('download.releaseDate', {date: moment(o.props.releaseDate).format('LL'),})} 
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <DownloadContainer>
                      <a
                        href={o.props.href}
                        className="btn btn-outline-secondary ml-2 btn-download"
                        target="_blank"
                      >
                        {t('download.download')}
                      </a>
                    </DownloadContainer>
                  </div>
                </div>
              </TabletPadding>
            </div>
          </>
        ))}
      </div>
    );
  }
  return null;
}

const TabletPadding = styled.div`
  @media (max-width: 990px) {
    padding-left: 30px;
  }
`;


const DownloadContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 30px;
`;

const OSIcon = styled.div`
  margin-top: 5px;
  height: 18px;
  position: relative;
  cursor: pointer;
  color: #0e103e;

  .os-windows,
  .os-macos,
  .os-chrome,
  .os-ios,
  .os-android {
    width: 50px;
    height: 50px;
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }

  .title {
    position: absolute;
    top: 0;
    left: 70px;
  }
  .os-windows {
    background: url(${imgWindows});
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }
  .os-macos {
    background: url(${imgMacos});
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
  }
  .os-chrome {
    background: url(${imgAndroid});
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }
  .os-ios {
    background: url(${imgIos});
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }
  .os-android {
    background: url(${imgAndroid});
    background-size: contain;
    background-position: left top;
    background-repeat: no-repeat;
    position: absolute;
  }
`;

export { DownloadSelector };
