import classNames from 'classnames';
import styled from 'styled-components';

export const FullWidthLine = styled.hr`
  @media (min-width: 768px) {
    @supports (width: 100vw) {
      display: block;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
    }
  }
`;

export const ToggleButton = styled.button.attrs(props => ({
  className: classNames(
    'btn text-muted rounded-0  border-top-0 border-right-0 border-left-0 mx-4',
    {
      'border-primary': props.active,
    }
  ),
}))`
  border-color: translucent;
  border-width: 2px;
  padding: 0;
`;
